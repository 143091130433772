<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
       <page-heading
        heading="Work Order Classification"
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <router-link :to="{ name: 'work-order-classifications' }">Back</router-link>
    </column>
  </columns>
  
  <columns>
    <column>
      <form>
        <columns>
          <column>
            <text-input classes="is-medium is-rounded" :value="work_order_classification.title" @input="updateTitle"
              :error="$root.errors.title" required>
              Title
            </text-input>
          </column>
          <column class="is-4">
            <switch-input
              classes="is-medium is-rounded"
              description="Will show all checklist data when drawing a PDF report."
              :value="work_order_classification.include_all_checklists"
              @input="toggleAllChecklists"
              :error="$root.errors.include_all_checklists"
              required>
              Expanded Report
            </switch-input>
          </column>
          <column class="is-narrow">
            <switch-input
              classes="is-medium is-rounded"
              description="Customer contacts will not receive notifications"
              :value="work_order_classification.internal"
              @input="toggleInternal"
              :error="$root.errors.internal"
              required>
              Internal Only
            </switch-input>
          </column>
        </columns>
        <columns>
          <column>
            <text-area classes="is-medium is-rounded" :value="work_order_classification.description" @input="updateDescription"
              :error="$root.errors.description">
              Description
            </text-area>
          </column>
        </columns>

        <team-segmentation />

        <report-configuration />
      </form>
    </column>
  </columns>


  <columns>
    <column>
      <submit-button class="is-medium is-rounded" :working="creating" :disabled="deleting" @submit="updateCategory">
        Save
      </submit-button>
    </column>
    <column class="is-narrow">
      <action-button class="is-small is-danger is-rounded" left-icon="trash" :disabled="creating" :working="deleting" @click="deleteClassification">
        Delete
      </action-button>
    </column>
  </columns>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ReportConfiguration from './report-configuration/ReportConfig.vue'
import TeamSegmentation from './segmentation/TeamSegmentation.vue'

export default {

  components: {
    ReportConfiguration,
    TeamSegmentation
  },

  data: () => ({
    loading: true,
    creating: false,
    deleting: false
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadTeamList')
    await this.$store.dispatch('workOrderClassification/load', this.$route.params.workOrderClassification)
    this.loading = false
  },

  methods: {
    ...mapMutations('workOrderClassification', [
      'updateTitle',
      'updateDescription',
      'toggleAllChecklists',
      'toggleInternal'
    ]),
    updateCategory() {
      this.creating = true
      this.$store.dispatch('workOrderClassification/update').then(() => {
        this.creating = false
        this.$toast.success('Saved')
      }).catch(() => this.creating = false)
    },
    async deleteClassification() {
      if(this.work_order_classification.work_orders_count) {
        return this.$toast.error('This classification cannot be deleted because it has work orders attached to it.')
      }

      if(await this.$confirm({
        title: 'Delete Work Order Classification',
        message: 'Are you sure you want to delete this classification?'
      })) {
        this.deleting = true
        this.$store.dispatch('workOrderClassification/deleteClassification').then(() => {
          this.deleting = false
          this.$toast.success('Successfully Deleted.')
          this.$router.push({
            name: 'work-order-classifications'
          })
        }).catch(error => {
          console.log(error)
          this.deleting = false
        })
      }
    }
  },

  computed: {
    ...mapGetters('workOrderClassification', [
      'work_order_classification'
    ])
  }

}
</script>