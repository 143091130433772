<template>
<div>
    <columns>
        <column>
            <h4 class="title is-4">Report Configuration</h4>
            <p class="has-text-grey">You can control how a report is rendered at time of PDF creation.</p>
        </column>
    </columns>
    <columns>
        <column>
            <switch-input
                :value="work_order_classification.configuration.branch"
                @input="toggleBranch">
                Show Branch Information
            </switch-input>
        </column>
        <column>
            <switch-input
                :value="work_order_classification.configuration.team_name"
                @input="toggleTeamName">
                Show Team Name
            </switch-input>
        </column>
        <column>
            <switch-input
                :value="work_order_classification.configuration.team_lead"
                @input="toggleTeamLead">
                Show Team Leader
            </switch-input>
        </column>
        <column>
            <switch-input
                :value="work_order_classification.configuration.dates"
                @input="toggleDates">
                Show Start and End Dates
            </switch-input>
        </column>
    </columns>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

    methods: {
        ...mapMutations('workOrderClassification', [
            'toggleBranch',
            'toggleTeamName',
            'toggleTeamLead',
            'toggleDates',
        ])
    },

    computed: {
        ...mapGetters('workOrderClassification', [
            'work_order_classification'
        ])
    }

}
</script>